<script>
import ModalReactivate from '@/components/general/ModalReactivate'
import ModalInviteList from '@/components/home/ModalInviteList'
export default {
  name: 'NewHome',
  components: {
    ModalInviteList,
    ModalReactivate
  },
  computed: {
    inDeleteProcess () {
      return this.selectedWorkspace && this.selectedWorkspace.type === 'business' && this.selectedWorkspace.deletionRequest && this.selectedWorkspace.deletionRequest.status !== 'CANCELED'
    },
    confirmationDetails () {
      return {
        username: this.$store.getters.getUser.name.split(' ')[0],
        date: this.formatDate(this.selectedWorkspace.deletionRequest.createdAt),
        workspaceOwner: `${this.selectedWorkspace.owner.name} ${this.selectedWorkspace.owner.lastName}`
      }
    },
    activeWorkspace () {
      return this.selectedWorkspace
    },
    userWorkspaces () {
      return this.$store.getters.getUserWorkspaces || []
    },
    getInviteDetails () {
      return this.$store.getters.getInviteDetails
    }
  },
  methods: {
    reactivateWorkspace () {
      this.$store.dispatch('attemptReactivateWorkspace', this.selectedWorkspace.lxID).then(() => {
        this.synchronizeUser().then(() => {
          this.$store.commit('setSelectedWorkspace', this.$store.getters.getUserWorkspaces.find(workspace => {
            return workspace.id === this.selectedWorkspace.id
          }))
        })
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'success',
          title: this.$t('workspace.reactive.feedback:title'),
          description: this.$t('workspace.reactive.feedback:description')
        })
      })
    },
    setActiveWorkspace (workspace = null) {
      this.$store.commit(
        'setSelectedWorkspace',
        workspace || this.userWorkspaces.find(workspaceOpt => workspaceOpt && workspaceOpt.type === 'business' && workspaceOpt.idpCompanyId) || this.userWorkspaces.find(workspaceOpt => workspaceOpt && workspaceOpt.type === 'business') || this.$store.getters.getIndividualWorkspace
      )
    },
    closeModal () {
      this.$store.commit('setInviteDetails', { showMessageInvite: false })
    }
  }
}
</script>
<template>
  <div class="homepage--container">
    <div class="homepage--body">
      <router-view></router-view>
    </div>
    <modal-reactivate
      v-if="inDeleteProcess"
      :buttonText="$t('workspace.deleted.recovery:button')"
      :description="$t('workspace.deleted.recovery:description')"
      :details="confirmationDetails"
      @reactivate="reactivateWorkspace"
    />
    <modal-invite-list/>

  </div>
</template>
<style lang="scss">
.homepage--container {
  display: flex;
  margin: 0 auto;
  padding-top: 104px;
  max-width: 1100px;
  text-align: left;

  .homepage--body {
    width: 100%;
    max-width: 100%;
    padding: 0 24px;
    .contents-list--list-wrapper {
      margin-bottom: 40px;
      .content-card--container {
        margin: 0 0 40px 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding-top: 100px;
    .homepage--body {
      width: 100%;
    }
  }
}
</style>
